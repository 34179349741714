@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://use.typekit.net/skr0ola.css);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative; }

html {
  height: 100%;

  body {
    height: 100%;

    @media (max-width: 768px) {
      overflow-x: hidden; } } }
