@keyframes shad-anim {
  0% {
    background-position: 0 0; }
  0% {
    background-position: 100% -100%; } }

@-webkit-keyframes openDownRightReturn {
  0% {
    transform-origin: bottom right;
    transform: rotate(110deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    transform-origin: bottom right;
    transform: rotate(0deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes openDownRightReturn {
  0% {
    transform-origin: bottom right;
    transform: rotate(110deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    transform-origin: bottom right;
    transform: rotate(0deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@-webkit-keyframes mailAnimation {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(-50deg);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(-160deg);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }
@keyframes mailAnimation {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(-50deg);
    opacity: 0;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(-160deg);
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }
#root {
  height: 100%; }
.content {
  height: 100%;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column; }

  h1 {
    display: inline-block;
    color: hsla(230,40%,50%,1);
    font-family: 'Righteous', serif;
    font-size: 12em;
    text-shadow: .03em .03em 0 hsla(230,40%,50%,1);

    @media (max-width: 1440px) {
      font-size: 10em; }

    @media (max-width: 576px) {
      font-size: 7em; }

    &:after {
      content: attr(data-shadow);
      position: absolute;
      top: .06em;
      left: .06em;
      z-index: -1;
      text-shadow: none;
      background-image: linear-gradient( 45deg, transparent 45%, hsla(230,40%,50%,1) 45%, hsla(230,40%,50%,1) 55%, transparent 0 );
      background-size: .05em .05em;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shad-anim 15s linear infinite; } }

  h2 {
    font-family: 'raleway';
    font-size: 100px;

    @media (max-width: 1440px) {
      font-size: 90px; }

    @media (max-width: 768px) {
      font-size: 55px; } }


  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    width: 60vw;

    @media (max-width: 1024px) {
      padding-top: 10%;
      padding-left: 5% !important;
      width: 60vw;
      justify-content: end; }

    @media (max-width: 768px) {
      width: 100%;
      margin: 15% 0;
      padding-top: 0;
      padding-left: 15px;
      min-height: 600px; }

    @media (max-width: 576px) {
      padding-left: 15px; }

    @media (max-width: 414px) {
      margin: 5% 0;
      margin-bottom: 20%; } }

  &__right {
    height: 100%;
    background: hsla(230,40%,50%,1);
    width: 40vw;
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
       width: 40vw; }

    @media (min-width: 577px) and (max-width: 768px) {
      flex-direction: row;
      justify-content: center;
      min-height: 500px;
      width: 100%;
      align-items: center; }

    @media (max-width: 576px) {
      width: 100%;
      min-height: 800px; } }

  &__contacts {
    display: flex;
    align-items: center;
    margin-top: 10%;

    a {
      padding-left: 25px;
      font-family: 'Righteous', serif;
      color: #000;
      font-size: 35px;
      font-style: normal;
      text-decoration: none;
      font-weight: bold;
      position: relative;

      @media (max-width: 768px) {
        font-size: 18px; }

      &:hover {

        .mailto {
          animation: openDownRightReturn;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both; } } }

    .mailto {
      position: absolute;
      bottom: 105%;
      left: 5%;
      opacity: 0;
      transform: rotate(-50deg);

      img {
        max-width: 50px; }

      &.hover {
        animation: mailAnimation ease-out infinite alternate;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        opacity: 1; } } }

  &__right-top {
    height: 50vh;
    overflow: hidden;
    padding: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1440px) {
      padding-top: 0; }

    @media (min-width: 577px) and (max-width: 768px) {
      width: 50%;
      height: 100%; }

    @media (max-width: 768px) {
      padding: 80px 15px; }

    h3 {
      color: #ffffff;
      font-family: 'raleway', serif;
      margin-top: 30px;
      margin-bottom: 2px;
      font-size: 14px; } }

  &__right-bottom {
    height: 50%;
    background-image: url('./imagining_myself.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    min-height: 50vh;

    @media (max-width: 768px) {
      min-height: 300px; }

    @media (min-width: 577px) and (max-width: 768px) {
      width: 50%; } }

  &__bio {
    width: 40%;
    margin-top: 2%;
    font-family: 'raleway', serif;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    left: 7%;
    top: 1%;
    box-shadow: 0 0 10px -2px;
    padding: 30px 30px;

    @media (max-width: 1440px) {
      width: 50%; }

    @media (max-width: 1024px) {
      width: 60%; }

    @media (max-width: 768px) {
      width: 90%;
      margin-top: 5%;
      left: 0;
      top: 0;
      font-size: 12px; } }

  &__skills-list {
    list-style: none;

    li {
      float: left;
      padding-right: 5px;
      padding-top: 5px;
      color: #fff;
      font-family: 'Righteous', serif;
      text-transform: uppercase;
      font-size: 15px;

      &:after {
        content: ','; }

      &:last-child {

        &::after {
          display: none; } } } }

  &__socials {
    margin-top: 3%;
    display: flex;
    color: #000;
    font-size: 35px;

    @media (max-width: 768px) {
      margin-top: 6%; } }

  &__social {
    margin-right: 15px;

    a {
      color: hsla(230,40%,50%,1); } } }
